<template>
  <div>
    <ed-table
      :key="$root.$session.versao"
      strModel="Cadastro/CadastroGeral"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getEmpresa"
      @acaoLote="salvarAcaoLote"
    >
      <template slot="table-acoes">
        <v-divider></v-divider>

        <h5>Outras Atualizações</h5>
        <div class="row">


          <ed-input-auto-complete
          v-if="$root.$session.filiais.length > 1"
            class="col-12 ma-0 pa-0"
            v-model="formDataLote.arrayFilial"
            label="Filial"
            name="arrayFilial"
            item-text="strNome"
            route="Cadastro/Filial"
            :filters="{
            }"
            clearable
            multiple
          />

        </div>
      </template>
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdInputAutoComplete,
  EdInputCheckbox,
  EdInputDate,
  EdButton,
  EdInputText,
  EdInputMoney,
  EdSimpleTable,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdTable,
    EdModal,
    EdInputAutoComplete,
    EdInputCheckbox,
    EdInputDate,
    EdButton,
    EdInputText,
    EdInputMoney,
    EdSimpleTable,
  },
  props: {
    intTipoCadastroGeralId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  mounted() {
    this.getEmpresa();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "CPJ/CNPJ",
          sortable: true,
          value: "strCnpj",
        },
        {
          text: "Nome",
          sortable: true,
          collapse: 20,
          value: "strNome",
        },
        {
          text: "Telefone",
          sortable: true,
          value: "strTelefone",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros: {
        intTipoCadastroGeralId: this.intTipoCadastroGeralId,
      },
      formDataLote: {
        arrayFilial: [],
      },
    };
  },
  methods: {


    onRegister() {
      this.$router.push({ name: this.$route.name+".register",intTipoCadastroGeralId:this.intTipoCadastroGeralId });
    },

    onEdit(data) {

      this.$router.push({
        name: this.$route.name+".edit",
        params: { intId: data.intId,intTipoCadastroGeralId:this.intTipoCadastroGeralId  },
      });
    },

    salvarAcaoLote(ids) {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .put("Cadastro/CadastroGeral", {
          boolAcaoLote: true,
          arrayId: ids,
          data: this.formDataLote,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.getEmpresa();
          }
        });
    },

    getEmpresa() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Cadastro/CadastroGeral", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objEmpresa) => {
              let item = {
                intId: objEmpresa.intId,
                strCnpj: objEmpresa.strCnpj,
                strNome: objEmpresa.strNome,
                strTelefone: objEmpresa.strTelefone,
                _item: objEmpresa,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
